import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import {
  UserDetailsDTO,
  UserFormDTO,
  UserListDTO,
  UserMinimalDTO,
} from '../dto/user.dto';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { convertResponseToUserStatusDetailsDTO } from '../../../settings/organization/dto/user-status.dto';
import { convertResponseToDesignationDetailsDTO } from '../../../settings/organization/dto/designation.dto';
import {
  convertResponseToRoleDetailsDTO,
  RoleDTO,
} from '../../../settings/general-settings/dto/role.dto';

@Injectable({
  providedIn: 'root',
})
export class UserConversion
  implements MainConversionInterface<UserListDTO, UserFormDTO, UserDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): UserListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        user: this.resObjToUserMinimalDTO(item),
        name: item[`first_name_${this.lang}`] + item[`last_name_${this.lang}`],
        initial: item.initial,
        email: item.email,
        designation_title: item[`designation_title_${this.lang}`],
        color: item.color,
        mobile_number: item.mobile_number,
        roles: item[`roles_${this.lang}`],
        user_status: this.tableConv.resToColordItem(item, 'user_status'),
      };
    });
    return result;
  }
  formGroupToForm(formGroup: FormGroup): UserFormDTO {
    throw new Error('Method not implemented.');
  }
  resToForm(response: any) {
    throw new Error('Method not implemented.');
  }
  resToDetails(response: any): UserDetailsDTO {
    return {
      id: response.id,
      first_name: response[`first_name_${this.lang}`],
      last_name: response[`last_name_${this.lang}`],
      email: response.email,
      mobile_number: response.mobile_number,
      last_login_at: response.last_login_at,
      profile_image_path: response.profile_image_path,
      initial: response.initial,
      color: response.color,
      created_at: response.created_at,
      updated_at: response.updated_at,
      organization_unit_1: response.organization_unit_1,
      organization_unit_2: response.organization_unit_2,
      organization_unit_3: response.organization_unit_3,
      organization_unit_4: response.organization_unit_4,
      organization_unit_5: response.organization_unit_5,
      user_status: convertResponseToUserStatusDetailsDTO(
        response.user_status,
        this.lang
      ),
      designation: convertResponseToDesignationDetailsDTO(
        response.designation,
        this.lang
      ),
      roles: response.roles.map((role: RoleDTO) =>
        convertResponseToRoleDetailsDTO(role, this.lang)
      ),
      created_by_user: this.resObjToUserMinimalDTO(response.created_by_user),
      updated_by_user: this.resObjToUserMinimalDTO(response.updated_by),
    };
  }

  resToUserMinimalDTO(response: any, key: string): UserMinimalDTO | null {
    if (!response) {
      return null;
    }
    return {
      id: response.id,
      first_name: response[`${key}_first_name_${this.lang}`],
      last_name: response[`${key}_last_name_${this.lang}`],
      profile_image_path: response[`${key}_profile_image_path`],
      initial: response[`${key}_initial`],
      color: response[`${key}_color_code`],
    };
  }

  resObjToUserMinimalDTO(response: any): UserMinimalDTO | null {
    if (!response) {
      return null;
    }
    return {
      id: response.id,
      first_name: response[`first_name_${this.lang}`],
      last_name: response[`last_name_${this.lang}`],
      profile_image_path: response[`profile_image_path`],
      initial: response[`initial`],
      color: response[`color_code`],
    };
  }
}
