export interface RoleDTO {
  id: number;
  title_en: string;
  title_ar?: string;
  status?: string;
}

export interface RoleFormDTO {
  id?: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface RoleDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export function convertResponseToRoleDetailsDTO(
  response: any,
  lang: string
): RoleDetailsDTO {
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
  };
}
